const INITIAL_STATE = {
  myAssessmentRecords: null,
  myAssessments: null,
  userAccount: null,
  myModules: null,
  userId: null,
  currentAssessment: null,
  otpError: null,
  metadata: {},
  assessmentSuccess: false,
  testAssessment: null,
  feedForwards: null,
  feedForwardSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "UPDATE_USERID":
      return { ...state, userId: action.data };
    case "UPDATE_USER_ACCOUNT":
      return { ...state, userAccount: action.data };
    case "UPDATE_FEEDFORWARD_RECORDS":
      return { ...state, feedForwards: action.data };
    case "UPDATE_ASSESSMENTS":
      return { ...state, myAssessments: action.data };
    case "UPDATE_ASSESSMENTS_RECORDS":
      return { ...state, myAssessmentRecords: action.data };
    case "UPDATE_MODULES":
      return {
        ...state,
        myModules: {
          ...state.myModules,
          [action.payload.id]: action.payload.data,
        },
        metadata: {},
      };
    case "UPDATE_CURRENT_ASSESSMENT":
      return {
        ...state,
        currentAssessment: action.data,
      };
    case "ASSESSMENT_START":
      return { ...state, assessmentSuccess: false };
    case "CURRENT_ASSESSMENT_FINISH":
      return {
        ...state,
        currentAssessment: null,
        assessmentSuccess: true,
      };
    case "OTP_ERROR":
      return { ...state, otpError: action.data };
    case "FEEDFORWARDS_SUCCESS_RESET":
      return { ...state, feedForwardSuccess: false };
    case "FEEDFORWARDS_SUCCESS":
      const update = Object.assign(state.metadata, action.data);
      return { ...state, metadata: update, feedForwardSuccess: true };
    case "SET_ASSESSMENT":
      return { ...state, testAssessment: action.data };
    default:
      return state;
  }
};
