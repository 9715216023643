import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useMediaQuery, Button, TextField } from "@material-ui/core";

import "./styles.scss";
import { Link, useParams, useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import { logFeedForwards } from "../../containers/app/redux_actions";

const FeedForward = (props) => {
  const mediaQuery = useMediaQuery("(min-width:980px)");
  const [practices, setPractices] = useState(3);
  const app = useSelector((state) => state.app);
  const metadata = app.metadata;
  const [feedForwards, setFeedForwards] = useState({});
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const feedForwardSuccess = app.feedForwardSuccess;

  const feedfoward = app.feedForwards || {};

  useEffect(() => {
    if (feedForwardSuccess) {
      dispatch({ type: "FEEDFORWARDS_SUCCESS_RESET" });
      history.push(`/module/${moduleId}`);
    }
  }, [feedForwardSuccess]);

  useEffect(() => {
    const currentFeedForward = feedfoward[moduleId];
    console.log("changed metadata ==> ", feedfoward, currentFeedForward);
  }, [feedfoward]);

  const addPractice = () => {
    if (practices < 5) {
      setPractices((prev) => prev + 1);
    }
  };

  const handleSave = () => {
    dispatch(
      logFeedForwards({
        uid: app.userId,
        moduleId: moduleId,
        feedForward: feedForwards,
        metadata: {
          [`ff001_${moduleId}`]: { isCompleted: true },
        },
      })
    );
  };

  const handleInputs = (practice, e) => {
    const feedforwards = { ...feedForwards, [practice]: e.target.value };
    setFeedForwards(feedforwards);
  };

  return (
    <React.Fragment>
      <div id="header-bar">
        <div className="header">
          <div className="top-line">
            <div className="left">
              <Link to="/app" className="b-arrow">
                <ArrowBack />
              </Link>
              <div className="h-title">
                {" "}
                {mediaQuery ? "Module Home" : "Feed Forward"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx("feedforward-wrapper", { desktop: mediaQuery })}>
        <div className="ff-title">
          <div className="container">
            {mediaQuery && <div className="ff-head-title">Feed Forward</div>}
            Based on your new learning and takeaways from this session, put down
            3 action steps that you would like to take or adopt into your life,
            starting now –
          </div>
        </div>

        {feedfoward[moduleId] &&
        feedfoward[moduleId].metadata[`ff001_${moduleId}`].isCompleted ? (
          <React.Fragment>
            <div className="form-container container">
              {Object.entries(feedfoward[moduleId].response).map((ff, i) => {
                const t = ff[0],
                  v = ff[1];
                return (
                  <React.Fragment key={i}>
                    {/* <div style={{ fontWeight: 500 }}>{t.replace(/_/, " ")}</div> */}
                    {/* <div>{v}</div> */}
                    <TextField
                      key={i}
                      fullWidth
                      label={`Practice ${i + 1}`}
                      placeholder={`Practice ${i + 1}`}
                      onKeyUp={(e) => handleInputs(`practice_${i + 1}`, e)}
                      className="practice-textfield"
                      value={v}
                    />
                  </React.Fragment>
                );
              })}
            </div>
            <div className="note container">
              TIP: <br />
              Translating the new thinking approaches you encountered into
              action immediately, is the best way to build yourself & become
              future-ready.
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <form className="form-container container">
              {Array(practices)
                .fill()
                .map((p, i) => {
                  return (
                    <TextField
                      key={i}
                      id={`Practice_$ {i + 1}`}
                      fullWidth
                      label={`Practice ${i + 1}`}
                      // placeholder={`Practice ${i + 1}`}
                      onKeyUp={(e) => handleInputs(`practice_${i + 1}`, e)}
                      className="practice-textfield"
                    />
                  );
                })}
            </form>

            {practices < 5 && (
              <div
                className="btn-container container addModule"
                onClick={addPractice}
              >
                <img src="/assets/add_new_module.svg" alt="add_new_module" />
              </div>
            )}

            <div className="note container">
              TIP: <br />
              Translating the new thinking approaches you encountered into
              action immediately, is the best way to build yourself & become
              future-ready.
            </div>

            <div className="save-btn-wrapper container">
              <Button
                className="save-btn"
                onClick={handleSave}
                disabled={
                  Object.values(feedForwards).filter((a) => a).length === 0
                }
              >
                Save
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default FeedForward;
