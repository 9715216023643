import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Auth from "./containers/auth";
import Public from "./containers/public";
import App from "./containers/app";
import Module from "./components/Module";
import Otp from "./components/Otp";
import Assessment from "./components/Assessment";
import Congrats from "./components/Congrats";
import Result from "./components/Results";
import FeedForward from "./components/FeedForward";

import "./Main.css";
import "./Typography.css";
import Results from "./components/Results";

let Main = class Main extends Component {
  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.props.auth.isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    const PublicRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          !this.props.auth.isLoggedIn ? (
            //<Component {...props} />
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/app",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    return (
      <Switch>
        <Route path="/auth" component={Auth} />
        <PrivateRoute path="/app" component={App} />
        <PrivateRoute path="/module/:moduleId" component={Module} />
        <PrivateRoute path="/otp/:moduleId/:testId" component={Otp} />
        <PrivateRoute
          path="/assessment/:moduleId/:testId"
          component={Assessment}
        />
        <PrivateRoute path="/congrats/:moduleId" component={Congrats} />
        <PrivateRoute path="/result/:moduleId" component={Result} />
        <PrivateRoute path="/feedforward/:moduleId" component={FeedForward} />
        <PublicRoute exact path="/" component={Public} />
      </Switch>
    );
  }
};

const mapStateToProps = (state) => ({ auth: state.auth });
export default withRouter(connect(mapStateToProps, null)(Main));
