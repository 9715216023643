import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import store from "../../Redux_Store";

let server =
  "https://us-central1-coaching-platform-illumine.cloudfunctions.net/serverResponse";

if (!firebase.apps.length) {
  const config = {
    apiKey: "AIzaSyC7wfwLtOf3RhyelZOz9u8jiMzda5jeDGI",
    authDomain: "coaching-platform-illumine.firebaseapp.com",
    databaseURL: "https://coaching-platform-illumine.firebaseio.com",
    projectId: "coaching-platform-illumine",
    storageBucket: "coaching-platform-illumine.appspot.com",
    messagingSenderId: "1002955816731",
    appId: "1:1002955816731:web:bae84931025bd045499dbe",
    measurementId: "G-DCKE8R2GHB",
  };
  firebase.initializeApp(config);
}
let db = firebase.database();

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch({ type: "UPDATE_USERID", data: user.uid });
    db.ref(`/users/${user.uid}/userAccount`).on("value", (snapshot) => {
      store.dispatch({ type: "UPDATE_USER_ACCOUNT", data: snapshot.val() });
    });

    db.ref(`/users/${user.uid}/learningAssessments`).on("value", (snapshot) => {
      store.dispatch({
        type: "UPDATE_ASSESSMENTS_RECORDS",
        data: snapshot.val(),
      });
    });

    db.ref(`/users/${user.uid}/feedForwards`).on("value", (snapshot) => {
      store.dispatch({
        type: "UPDATE_FEEDFORWARD_RECORDS",
        data: snapshot.val(),
      });
    });

    db.ref(`/users/${user.uid}/mySubscriptions`)
      .orderByChild("app")
      .equalTo("learningApp")
      .on("value", (snapshot) => {
        let d = snapshot.val();
        if (d) {
          Object.keys(d).forEach((el) => {
            let moduleSynopsis = d[el];
            db.ref(`modules/${el}`)
              .once("value")
              .then((s) => {
                // fetch_({
                //   uid: user.uid,
                //   moduleId: el,
                //   feedForward: {},
                //   metadata: {},
                // });
                store.dispatch({
                  type: "UPDATE_MODULES",
                  payload: { id: el, data: s.val() },
                });
              });
          });
        }

        //store.dispatch({ type: 'UPDATE_MY_LEARNING_APP', data: snapshot.val() })
      });
  } else {
    //console.log("no user");
  }
});

// ACCESS TOKEN

// URL: /access-token

export const setAssessment = ({ test }) => {
  return (dispatch) => {
    dispatch({
      type: "SET_ASSESSMENT",
      data: test,
    });
  };
};

export const serviceOTP = ({ uid, otp, assessmentTestId }) => {
  return (dispatch) => {
    let requestOptions = {
      method: "POST",
      body: JSON.stringify({ uid, otp, assessmentTestId }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(`${server}/serviceOTP`, requestOptions)
      .catch((err) => {
        console.log(err);
      })
      .then((response) => {
        response.json().then((response) => {
          if (!response.success) {
            console.log(response);
            dispatch({
              type: "OTP_ERROR",
              data:
                response.msg === "server error"
                  ? { msg: "OTP is incorrect, please try another" }
                  : response,
            });

            setTimeout(() => {
              dispatch({
                type: "OTP_ERROR",
                data: null,
              });
            }, 3000);
          } else {
            console.log(response);
            dispatch({
              type: "UPDATE_CURRENT_ASSESSMENT",
              data: response,
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: "OTP_ERROR",
          data: { msg: "OTP is incorrect, please try another" },
        });

        setTimeout(() => {
          dispatch({
            type: "OTP_ERROR",
            data: null,
          });
        }, 3000);
      });
  };
};

export const logFeedForwards = ({ uid, moduleId, feedForward, metadata }) => {
  return (dispatch) => {
    let requestOptions = {
      method: "POST",
      body: JSON.stringify({ uid, moduleId, feedForward, metadata }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(`${server}/logUserFeedforwards`, requestOptions)
      .catch((err) => {
        console.log(err);
      })
      .then((response) => {
        response.json().then((response) => {
          if (!response.success) {
            console.log(response);
            dispatch({
              type: "FEEDFORWARD_ERROR",
              data:
                response.msg === "server error"
                  ? { msg: "OTP is incorrect, please try another" }
                  : response,
            });
          } else {
            console.log("feed forward success ==>", response, dispatch);
            dispatch({
              type: "FEEDFORWARDS_SUCCESS",
              data: metadata,
            });
          }
        });
      });
  };
};

export const logUserResponse = ({
  uid,
  assessmentTestId,
  assessmentSessionId,
  response,
  metadata,
}) => {
  return (dispatch) => {
    let requestOptions = {
      method: "POST",
      body: JSON.stringify({
        uid,
        assessmentTestId,
        assessmentSessionId,
        response,
        metadata,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(`${server}/logUserResponse`, requestOptions)
      .catch((err) => {
        console.log(err);
      })
      .then((response) => {
        response.json().then((response) => {
          if (!response.success) {
            console.log("reponse failed ==> ", response);
          } else {
            console.log("response sussess ==> ", response, metadata);
            if (metadata[assessmentTestId].isCompleted) {
              dispatch({
                type: "CURRENT_ASSESSMENT_FINISH",
              });
            }
          }
        });
      });
  };
};

function fetch_({ uid, moduleId, feedForward, metadata }) {
  let requestOptions = {
    method: "POST",
    body: JSON.stringify({ uid, moduleId, feedForward, metadata }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  fetch(
    `https://us-central1-coaching-platform-illumine.cloudfunctions.net/serverResponse/logUserFeedforwards`,
    requestOptions
  )
    .catch((err) => {
      console.log(err);
    })
    .then((response) => {
      response.json().then((response) => {
        console.log(response);
      });
    });
}
