import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

import AccessCode from "../../components/AccessCode";

import "./auth.scss";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

const Auth = () => {
  const mediaQuery = useMediaQuery("(min-width:980px)");
    return (
      <div className={clsx({desktop: mediaQuery})}>
      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/register" component={Register} />
        <Route path="/auth/reset-password" component={ForgotPassword} />
        <Route path="/auth/accesscode" component={AccessCode} />
        <Route path="/auth/" component={Login} />
      </Switch>
      </div>
    );
};

export default Auth;
