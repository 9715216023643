import React from "react";
import { Button, useMediaQuery } from "@material-ui/core";

import Header from "../Header";
import "./styles.scss";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Congrats = () => {
  const { moduleId } = useParams();
  const mediaQuery = useMediaQuery("(min-width:980px)");
  const app = useSelector((state) => state.app);
  const records = app.myAssessmentRecords || {};
  const modules = app.myModules || [];

  let metadata = {};
  Object.values(records).forEach((r) => {
    if (r.metadata) {
      Object.assign(metadata, r.metadata, app.metadata);
    }
  });

  const getModuleStatus = (moduleId) => {
    const la = modules[moduleId].learningAssessments;

    let isCompleted = false;
    Object.keys(la).forEach((m) => {
      const test = metadata[m];
      if (test && test.isCompleted) {
        isCompleted = true;
      } else {
        isCompleted = false;
      }
    });

    return isCompleted;
  };

  return (
    <React.Fragment>
      <Header />
      <div className="congrats-wrapper">
        <div className="c-icon">
          <img src="/assets/test_complete_icon.svg" alt="congrats" />
        </div>
        <div className="c-title">Congratulations!</div>
        <div className="c-caption">
          You have completed the
          <br hidden={mediaQuery} /> assessment!
        </div>
        <div>
          {getModuleStatus(moduleId) && false ? (
            <Link className="back-home" to={`/result/${moduleId}`}>
              Show Results
            </Link>
          ) : (
            <Link className="back-home" to={`/module/${moduleId}`}>
              Back to Home
            </Link>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Congrats;
