import React from "react";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import Dashboard from "../../components/Dashboard";
import "./redux_actions";
import { PowerSettingsNew } from "@material-ui/icons";
import { signOut } from "../../containers/auth/redux_actions";

import "./styles.scss";
import { useDispatch } from "react-redux";
const App = (props) => {
  const mediaQuery = useMediaQuery("(min-width:980px)");
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(signOut());
  };

  return (
    <div className={clsx("home", { desktop: mediaQuery })}>
      <div className="d-header">
        <div className="left">
          <div className="logo-wrapper">
            <img src="/assets/phone_pedgog_P_logo.svg" alt="pedgog_logo" />
          </div>
          <div className="lines-wrapper">
            <div className="fline">i-Become assessment for</div>
            <div className="sline">Foundations of Self</div>
          </div>
        </div>
        <div className="right" onClick={logout}>
          <PowerSettingsNew />
        </div>
      </div>
      <Dashboard />
    </div>
  );
};

export default App;
