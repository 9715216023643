import React from "react";
import clsx from "clsx";

import "./styles.scss";
import { Grid, useMediaQuery } from "@material-ui/core";
import { ArrowForward, PowerSettingsNew, ArrowBack } from "@material-ui/icons";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { signOut } from "../../containers/auth/redux_actions";
import { setAssessment } from "../../containers/app/redux_actions";

const Module = () => {
  const { moduleId } = useParams();
  const app = useSelector((state) => state.app);

  const feedforwards = app.feedForwards;
  const modules = app.myModules;
  const dispatch = useDispatch();

  const mediaQuery = useMediaQuery("(min-width:980px)");

  const module = modules[moduleId];
  const modulename = module.title;
  const learningAssessments = module.learningAssessments;
  const records = app.myAssessmentRecords || {};
  let metadata = {};

  Object.values(records).forEach((r) => {
    if (r.metadata) {
      Object.assign(metadata, r.metadata, app.metadata);
    }
  });

  const logout = () => {
    dispatch(signOut());
  };

  const handleLink = (test, testId) => {
    const _test = test.includes("pre") ? "pre" : "post";

    dispatch(setAssessment({ test: _test }));
  };

  const currentFeedForward =
    feedforwards && feedforwards[moduleId] ? feedforwards[moduleId] : {};

  return (
    <React.Fragment>
      <div className="header m-header">
        <div className="top-line">
          <div className="left">
            <Link to="/app" className="b-arrow">
              <ArrowBack />
            </Link>
            <div className="h-title">Foundations of Self</div>
          </div>
          <div className="right" onClick={logout}>
            <PowerSettingsNew />
          </div>
        </div>
        <div className="second-line">{modulename}</div>
      </div>
      <div className={clsx("module-wrapper", { desktop: mediaQuery })}>
        {Object.entries(learningAssessments).map((la, i) => {
          const testId = la[0],
            assessment = la[1];
          return (
            <Link
              key={i}
              className="p-module"
              to={
                metadata[testId] && metadata[testId].isCompleted
                  ? `#`
                  : `/otp/${moduleId}/${testId}`
              }
              onClick={() => handleLink(assessment.title, testId)}
            >
              <Grid container>
                <Grid item xs={2} className="grid">
                  <div className="pm-number">{i + 1}</div>
                </Grid>
                <Grid item xs={8} className="grid">
                  <div className="pm-title">{assessment.title}</div>
                </Grid>
                <Grid item xs={2} className="grid">
                  <div className="pm-icon">
                    {metadata[testId] && metadata[testId].isCompleted ? (
                      <div className="m-completed">
                        <img src="/assets/test_complete_icon.svg" alt="icon" />
                        <br />
                        Completed
                      </div>
                    ) : (
                      <ArrowForward />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Link>
          );
        })}
        {false && (
          <Link className="p-module" to={`/feedforward/${moduleId}`}>
            <Grid container>
              <Grid item xs={2} className="grid">
                <div className="pm-number">
                  {Object.entries(learningAssessments).length + 1}
                </div>
              </Grid>
              <Grid item xs={8} className="grid">
                <div className="pm-title">Feed Forward</div>
              </Grid>
              <Grid item xs={2} className="grid">
                <div className="pm-icon">
                  {currentFeedForward &&
                  currentFeedForward.metadata[`ff001_${moduleId}`] &&
                  currentFeedForward.metadata[`ff001_${moduleId}`]
                    .isCompleted ? (
                    <div className="m-completed">
                      <img src="/assets/test_complete_icon.svg" alt="icon" />
                      <br />
                      Completed
                    </div>
                  ) : (
                    <ArrowForward />
                  )}
                </div>
              </Grid>
            </Grid>
          </Link>
        )}
      </div>
    </React.Fragment>
  );
};

export default Module;
