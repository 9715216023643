/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { ArrowBack } from "@material-ui/icons";

import "./styles.scss";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import { logUserResponse } from "../../containers/app/redux_actions";

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const Assessment = () => {
  const app = useSelector((state) => state.app);
  const history = useHistory();
  const assessmentSuccess = app.assessmentSuccess;
  const currentAssessment = app.currentAssessment;

  const [optionDisplayed, setOptionDisplayed] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [qSet, setqSet] = useState([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [buttonText, setButtonText] = useState("Confirm");
  const mediaQuery = useMediaQuery("(min-width:980px)");
  const [responseData, setResponseData] = useState({});
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const { testId, moduleId } = useParams();
  
  let firstQ = null;
  const dispatch = useDispatch();
  const questions =
    (currentAssessment &&
      currentAssessment.assessment &&
      currentAssessment.assessment.questions) ||
    {};

  useEffect(() => {
    dispatch({ type: "ASSESSMENT_START" });
    if (activeQuestionIndex+1 === Object.keys(questions).length) {
      setButtonText("Finish");
    };
    setqSet(shuffleArray(Object.keys(currentAssessment.assessment.questions)));
    console.log(qSet);
    firstQ = qSet?qSet[0]:null;
    console.log(qSet);
      setCurrentQuestionId(firstQ);
      setActiveQuestion(questions[firstQ]);
  }, [qSet.length]);

  useEffect(() => {
    if (assessmentSuccess) {
      history.push(`/congrats/${moduleId}`);
    }
  }, [assessmentSuccess]);

  console.log(activeQuestionIndex);

  const handleNext = () => {
    if (buttonText.toLowerCase() !== "finish") {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }

    const nextQ = qSet[activeQuestionIndex + 1];
    let metadata = { [testId]: { isCompleted: false } };
    if (activeQuestionIndex + 1 > Object.keys(questions).length) {
      metadata[testId].isCompleted = true;
    }
    dispatch(
      logUserResponse({
        uid: app.userId,
        assessmentTestId: testId,
        assessmentSessionId: currentAssessment.assessmentSessionId,
        response: responseData,
        metadata: metadata,
      })
    );

    if (activeQuestionIndex + 1 === Object.keys(questions).length) {
      setButtonText("Finish");
    } else if (questions[nextQ]) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
      setCurrentQuestionId(nextQ);
      setActiveQuestion(questions[nextQ]);
    }
    setOptionDisplayed(null);
  };

  const handlePrevious = () => {
    setActiveQuestionIndex(activeQuestionIndex - 1);
    const prevQ = qSet[activeQuestionIndex - 1];
    setCurrentQuestionId(prevQ);
    if (activeQuestionIndex - 1 < 1 || activeQuestionIndex === 1) {
      history.goBack();
    } else if (activeQuestionIndex - 1 < Object.keys(questions).length) {
      setButtonText("Confirm");
    } else {
      setActiveQuestion(questions[prevQ]);
    }
    setOptionDisplayed(null);
  };

  const optionChanged = (queId, optionId) => {
    setResponseData({ ...responseData, [queId]: optionId });
  };

  const getOptions = (options) => {
    if (!optionDisplayed) {
      setOptionDisplayed(shuffleArray(Object.keys(options)));
    }
  };

  console.log(currentQuestionId);

  return (
    <React.Fragment>
      {activeQuestion && (
        <div className={clsx("assesment-wrapper", { desktop: mediaQuery })}>
          <div className="header" hidden={mediaQuery}>
            <div className="top-line">
              <div className="left">
                <div className="b-arrow" onClick={handlePrevious}>
                  <ArrowBack />
                </div>
                <div className="h-title">Previous Question</div>
              </div>
            </div>
          </div>
          <div className="q-wrapper">
            {mediaQuery && (
              <div className="b-arrow" onClick={handlePrevious}>
                <ArrowBack />
              </div>
            )}
            <div className="q-container">
              <div className="q-indicator">
                {activeQuestionIndex + 1} of {Object.keys(questions).length}
              </div>
              <div className="ques">{activeQuestion.qText}</div>
            </div>
          </div>
          <div className="q-container o-container">
            <div className="q-options">
              <RadioGroup
                value={responseData[currentQuestionId] || null}
                onChange={(ev) =>
                  optionChanged(currentQuestionId, ev.target.value)
                }
              >
                {getOptions(activeQuestion.options)}
                {(optionDisplayed || []).map((o, i) => {
                  const k = o,
                    option = activeQuestion.options[o];
                  return (
                    <FormControlLabel
                      className="option"
                      value={k}
                      key={k}
                      control={<Radio />}
                      label={option.text}
                    />
                  );
                })}
              </RadioGroup>
            </div>
            <Button
              className="begin-btn"
              onClick={handleNext}
              disabled={!responseData[currentQuestionId]}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Assessment;
