import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router";

import LoginForm from "../../components/LoginForm";
// import ForgotPassword from "./ForgotPassword";

import { loginAttempt, setErrMsg, resetLoginPage } from "./redux_actions";
import clsx from "clsx";

let Login = class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      matches: window.matchMedia("(min-width: 768px)").matches
    };

    this.notLoggedIn = (classes) => {
      return (
        <div className={clsx("auth-cntr")}>
          <div className="pedgog_logo">
            <img src="/assets/phone_pedgog_text_logo.svg" alt="pedgog_logo" />
          </div>
          <div className="login-artifact" />
          <LoginForm
            loginAttempt={this.props.loginAttempt}
            authMsg={this.props.auth.loginErrorMsg}
          />
          <div className="auth-footer">An illumine learning platform</div>
        </div>
      );
    };

    this.loggedIn = (
      <Redirect
        to={{
          pathname: "/app",
        }}
      />
    );
  }

  componentDidMount() {
    //reset all the values in the state;
    this.props.resetLoginPage();

    const handler = e => this.setState({matches: e.matches});

    window.onresize = handler
    window.matchMedia("(min-width: 768px)").addListener(handler);

    console.log('this state ==> ', this.state);
  }

  render() {
    //this.props.requestPermissionToNotify();
    const { classes } = this.props;
    if (
      this.props.location.pathname === "/channel" &&
      this.props.auth.isLoggedIn
    ) {
      return;
    } else if (this.props.auth.isLoggedIn) {
      return this.loggedIn;
    } else {
      return this.notLoggedIn(classes);
    }
  }
};

const mapStateToProps = (state) => ({
  notification: state.notification,
  auth: state.auth
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAttempt,
      setErrMsg,
      //requestPermissionToNotify,
      resetLoginPage
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Login);
