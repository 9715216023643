import React from "react";

import "./styles.scss";
import Header from "../Header";
import { Button } from "@material-ui/core";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const Results = () => {
  const { moduleId } = useParams();

  const results = [
    {
      title: "part a: learning assessment",
      sections: [
        {
          title: "Concept of trust surplus",
          rank: 3,
          caption: "Benchmark.",
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <Header />
      <div className="r-wrapper">
        <div className="r-title">Assessment Report</div>
        {results.map((r, i) => {
          return (
            <div className="rs-container" key={i}>
              <div className="rs-head">{r.title}</div>
              <div className="s-container">
                {r.sections.map((s, j) => {
                  return (
                    <div key={j} className="section-wrapper">
                      <div className="sw-title">{s.title}</div>
                      <div className="rank-wrapper">
                        <div className="rank">{s.rank}</div>
                        <div className="caption">{s.caption}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <Link className="begin-btn" to={`/module/${moduleId}`}>
          Close
        </Link>
      </div>
    </React.Fragment>
  );
};

export default Results;
