import React, { useEffect, useState } from "react";
import { Button, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

import Modal from "../Modal";
import "./styles.scss";
import { Link, useHistory, useParams } from "react-router-dom";

import { serviceOTP } from "../../containers/app/redux_actions";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header";

const Otp = () => {
  const { testId, moduleId } = useParams();
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const myAssessments = app.myAssessments;
  const currentAssessment = app.currentAssessment;
  const mediaQuery = useMediaQuery("(min-width:980px)");

  useEffect(() => {
    dispatch({ type: "ASSESSMENT_START" });
    setOpen(true);
  }, []);

  useEffect(() => {
    if (currentAssessment && currentAssessment.assessment) {
      setOpen(false);
    }
  }, [currentAssessment]);

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  const handleOtp = (e) => {
    const v = e.target.value;
    setOtp(v);
  };

  const handleUnlock = () => {
    const otpPayload = {
      otp: otp.toUpperCase(),
      uid: auth.user_uid,
      assessmentTestId: testId,
    };

    dispatch(serviceOTP(otpPayload));
  };

  return (
    <React.Fragment>
      <Header />
      <div className={clsx("otp-wrapper", { desktop: mediaQuery })}>
        <div className="instructions-wrapper">
          <div className="i-head">Instructions</div>
          <div>
            {currentAssessment &&
              currentAssessment.assessment &&
              currentAssessment.assessment.instructions.map((ins, i) => {
                return (
                  <div key={i} className="i-line">
                    <span className="num">{i + 1}.</span>
                    {ins}
                  </div>
                );
              })}
          </div>
        </div>
        <Link className="begin-btn" to={`/assessment/${moduleId}/${testId}`}>
          Begin Test
        </Link>
        {open && (
          <Modal open={open} handleClose={handleClose}>
            <div className="otp-dialog">
              {app.otpError && (
                <div className="errorMsg" style={{ color: "red" }}>
                  {app.otpError.msg}
                </div>
              )}
              <div className="title">
                Enter the OTP sent by the instructor to unlock the test
              </div>
              <input
                type="text"
                className="otp-input"
                autoFocus
                onKeyUp={handleOtp}
              />
              <Button
                variant="contained"
                color="primary"
                className="unlock-btn"
                disabled={otp ? false : true}
                onClick={handleUnlock}
              >
                Unlock
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default Otp;
