import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#FFBC36",
    },
    secondary: {
      main: "#FF9761",
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0,0,0,.6)",
      },
    },
    MuiDialog: {
      paper: {
        margin: "20px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "25px",
      },
      contained: {
        backgroundColor: "#FFFFFF",
      },
      containedPrimary: {
        borderRadius: "25px",
      },
      containedSizeLarge: {
        padding: "8px 42px",
        fontSize: ".8rem",
      },
    },
  },
});
