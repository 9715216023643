import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

import "./styles.scss";
import { FiberManualRecord, CheckCircle } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import { resetToast } from "../../containers/auth/redux_actions";

const Dashboard = () => {
  const mediaQuery = useMediaQuery("(min-width:980px)");

  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);

  const records = app.myAssessmentRecords || {};
  let metadata = {};
  Object.values(records).forEach((r) => {
    if (r.metadata) {
      Object.assign(metadata, r.metadata, app.metadata);
    }
  });

  const modules = app.myModules || [];
  const dispatch = useDispatch();

  const getActiveText = (active) => {
    switch (active) {
      case "active":
        return {
          label: "Active",
          icon: <FiberManualRecord className="active" />,
        };
      case "complete":
        return {
          label: "Complete",
          icon: <CheckCircle />,
        };
      default:
        return {};
    }
  };

  const getModuleStatus = (moduleId) => {
    const la = modules[moduleId].learningAssessments || {};
    let isCompleted = false;
    Object.keys(la).forEach((m) => {
      const test = metadata[m];
      if (test && test.isCompleted) {
        isCompleted = true;
      } else {
        isCompleted = false;
      }
    });

    // To Check the feedforward is Completed or not

    if (isCompleted) {
      if (
        metadata[`ff001_${moduleId}`] &&
        metadata[`ff001_${moduleId}`].isCompleted
      ) {
        isCompleted = true;
      } else {
        isCompleted = false;
      }
    }
    return isCompleted;
  };

  const closeToast = () => {
    dispatch(resetToast());
  };

  return (
    <React.Fragment>
      <div className="dashboard-wrapper">
        <Grid container spacing={mediaQuery ? 4 : 1}>
          {Object.entries(modules).map((mod, i) => {
            const m = mod[1],
              k = mod[0];
            return (
              <Grid item xs={12} md={4} lg={4} key={i}>
                <Link
                  className="module"
                  style={{ ...(m.locked ? { opacity: ".6" } : { opacity: 1 }) }}
                  to={getModuleStatus(k) || m.locked ? "#" : `/module/${k}`}
                >
                  <div className="m-indicator">Module {i + 1}</div>
                  <Grid container>
                    <Grid item xs={10}>
                      <div className="m-title">{m.title}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="m-icon">
                        {getModuleStatus(k) ? (
                          <div className="m-completed">
                            <img
                              src="/assets/test_complete_icon.svg"
                              alt="icon"
                            />
                            <br />
                            Completed
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>

                  <div className="a-indicator">
                    {getActiveText(m.active).label}
                  </div>
                  {m.locked && (
                    <div
                      style={{
                        textTransform: "uppercase",
                        fontSize: ".815rem",
                      }}
                    >
                      Coming Soon
                    </div>
                  )}
                </Link>
              </Grid>
            );
          })}
        </Grid>
        {auth.modulesAdded && (
          <div className="toast-msg">
            <div className="msg">
              Congratulations! {Object.entries(modules).length} modules have{" "}
              <br /> now been added to your profile.
            </div>
            <button onClick={closeToast}>close</button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
