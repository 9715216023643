import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PowerSettingsNew, ArrowBack } from "@material-ui/icons";
import { signOut } from "../../containers/auth/redux_actions";
import "./header.css";

let Header = class Header extends Component {
  render() {
    const { moduleId } = this.props.match.params;

    return (
      <div id="header-bar">
        <div className="header">
          <div className="top-line">
            <div className="left">
              <Link to={`/module/${moduleId}`} className="b-arrow">
                <ArrowBack />
              </Link>
              <div className="h-title">Module Home</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ signOut }, dispatch);
}

export default connect(null, matchDispatchToProps)(withRouter(Header));
