import React, { useState, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";

import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";

import {
  serviceAccessCode,
  setToast,
} from "../../containers/auth/redux_actions";
import { Redirect, useHistory } from "react-router";

const AccessCode = () => {
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);
  const [accessCode, setAccessCode] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (auth.accessCodeSuccess) {
      history.push("/app");
      dispatch(setToast());
    }
  }, [auth.accessCodeSuccess, history]);

  const handleAccessCode = (e) => {
    const v = e.target.value;
    setAccessCode(v);
  };

  const handleUnlock = () => {
    const accessCodePayload = {
      uid: app.userId,
      accessCode: accessCode.toUpperCase(),
    };
    dispatch(serviceAccessCode(accessCodePayload));
  };

  return (
    <div className="auth-cntr">
      <div className="pedgog_logo">
        <img src="/assets/phone_pedgog_text_logo.svg" alt="pedgog_logo" />
      </div>
      <div className="access-code-wrapper">
        <div className="ac-head">
          Welcome! <br /> Your registration was successful.
        </div>
        <div className="ac-caption">
          Please input the ACCESS CODE provided to you so we create your session
          list
        </div>

        <TextField
          fullWidth
          placeholder="Access Code"
          className="access-code"
          onKeyUp={handleAccessCode}
        ></TextField>
        {auth.accessCodeError && (
          <div className="error">{auth.accessCodeError.msg}</div>
        )}

        <Button
          variant="contained"
          className="submit-btn"
          onClick={handleUnlock}
          disabled={accessCode ? false : true}
        >
          Unlock
        </Button>
      </div>

      <div className="auth-footer">An illumine learning platform</div>
    </div>
  );
};

export default AccessCode;
