import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./Registration.css";

const styles = {};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      accesscode: "",
      fullname: "",
    };

    this.handleChange = (prop) => (event) => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={"auth-form-cntr"}>
        <form>
          <div className="registerform-cntr">
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Full name</InputLabel>
              <Input
                id="fullname"
                label="Full Name"
                type="text"
                value={this.state.fullname}
                onChange={this.handleChange("fullname")}
              />
            </FormControl>

            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Email</InputLabel>
              <Input
                id="user-email"
                label="Email"
                type="text"
                autoComplete="on"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
            </FormControl>

            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="user-password"
                label="Password"
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {!this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl className="input-cntr forgot-pw-wrapper">
              <Button
                className="forgot-pw"
                component={Link}
                to="/auth/reset-password"
              >
                Forgot password?
              </Button>
            </FormControl>

            {/* <FormControl
            fullWidth
            className={"input-cntr"}
          >
            <InputLabel htmlFor="adornment-password">Access Code</InputLabel>
            <Input
              id="accesscode"
              label="Access Code"
              type="text"
              autoComplete="on"
              value={this.state.accesscode}
              onChange={this.handleChange("accesscode")}
            />
          </FormControl> */}

            <div className="errorMsg">{this.props.authMsg}</div>
            <FormControl fullWidth className={"input-cntr"}>
              <Button
                variant="contained"
                color="primary"
                className="submit-btn"
                onClick={() => {
                  let { email, password, accesscode, fullname } = this.state;
                  this.props.registerAttempt({
                    email,
                    password,
                    accesscode,
                    fullname,
                    successCallBack: () => {
                      // console.debug("success");
                    },
                    failureCallback: () => {
                      // console.log("failed");
                    },
                  });
                }}
              >
                Register
              </Button>
            </FormControl>

            <div
              className="login-link-cntr register-btn-wrapper"
              style={{ fontSize: "12px" }}
            >
              <span style={{ marginBottom: "5px", display: "block" }}>
                {" "}
                Already have an account?{" "}
              </span>
              <Link
                className="login-btn"
                to={"/auth/login"}
                style={{
                  color: "#E7302A",
                  textDecoration: "none",
                  fontWeight: 500,
                }}
              >
                <span className="login-link">Login</span>
                <span className="only-desktop-link"> Log In</span>
              </Link>
            </div>

            <div className="d-footer">
              By continuing, you agree to accept our <br /> Privacy Policy &
              Terms of Service.
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  registerAttempt: PropTypes.func,
  authMsg: PropTypes.string,
};

export default withStyles(styles)(Registration);
